<template>
  <PageContainer :title="title" :back="actions.back" ref="pageContainer">
    <template v-slot:content>
      <div
        class="category page-segment margin-top"
        v-if="category && category.length && !loading"
      >
        <DisplayCard
          class="carrousel-item"
          id="carrousel-item"
          v-for="(item, index) in category"
          :key="index"
          :display-config="displayConfig(item)"
          :cover-icon="item.icon"
          :duration-icon="item.content_format.icon"
          @click="onClick(item)"
        />
      </div>

      <div
        class="page-segment margin-top"
        v-if="category && category.length === 0 && loading"
      >
        <div class="for-you-category-skeleton">
          <div v-for="index in 4" :key="index">
            <Skeleton
              class="margin-bottom-xs"
              width="100%"
              height="150px"
              borderRadius="8px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="100%"
              height="22px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="60%"
              height="15px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="30%"
              height="15px"
              borderRadius="16px"
            />
          </div>
        </div>
      </div>

      <DataZeroSimple
        v-if="!loading && category && category.length === 0"
        :text="$translations['for-you']['datazero-group']"
        :image="require('@/assets/icons/search/search-default.svg')"
      />
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  DataZeroSimple,
  Skeleton,
  DisplayCard
} from '@seliaco/red-panda'
import MediaContentMixin from '@/mixins/media-content-mixin'
import { mapGetters } from 'vuex'
import { ForYouEvent } from '@/types/events'

export default {
  name: 'ForYouCategory',
  mixins: [MediaContentMixin],
  components: {
    PageContainer,
    DisplayCard,
    DataZeroSimple,
    Skeleton
  },
  data () {
    const actions = {
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.push({
              path: this.$route.query.back
            })
          } else {
            this.$router.replace({
              name: 'ForYou'
            })
          }
        }
      }
    }

    return {
      actions
    }
  },
  created () {
    if (!this.categories.length) {
      this.$store.dispatch('forYou/setup')
    }

    if (this.$route.params?.category) {
      let params = {
        category: this.$route.params.category,
        page: 1,
        per_page: 100
      }

      if (this.$route.params?.group) {
        params = {
          ...params,
          group: this.$route.params?.group
        }
      }

      this.$store.dispatch('forYou/list', {
        params,
        page: true
      })
    } else {
      this.$router.replace({
        name: 'ForYou'
      })
    }
  },
  destroyed () {
    this.$store.commit('forYou/SET_CATEGORY', { category: [] })
  },
  methods: {
    onClick (item) {
      const body = {
        page: this.$route.name,
        origin: 'Para ti - Categoría',
        component: 'Card',
        ...item
      }
      ForYouEvent.contentCardOpen(body)
      this.showDetail(item)
    }
  },
  computed: {
    title () {
      if (this.categories.length) {
        return this.categories.find(
          (g) => g.code === this.$route.params.category
        ).text
      }
      return ''
    },
    displayConfig () {
      return (value) => {
        const category = value.content_type.text
        const duration = this.durationFormat(value.duration)

        return {
          ...value,
          duration: `${category} • ${duration}`
        }
      }
    },
    ...mapGetters({
      loading: 'forYou/loading',
      category: 'forYou/category',
      categories: 'forYou/categories'
    })
  }
}
</script>

<style lang="sass" scoped>
.category,

.for-you-category-skeleton
  display: grid
  gap: 8px
  grid-template-columns: repeat(2, 1fr)

  @media (min-width: 480px)
    grid-template-columns: repeat(4, 1fr)
</style>
