import { ForYou } from '@seliaco/red-panda'

export default {
  methods: {
    durationFormat (seconds) {
      let hour = Math.floor(seconds / 3600)
      hour = hour < 10 ? '0' + hour : hour
      let minute = Math.floor((seconds / 60) % 60)
      minute = minute < 10 ? '0' + minute : minute
      let second = seconds % 60
      second = second < 10 ? '0' + second : second

      const translations = this.$translations.home['content-library']

      if (seconds >= 3600) {
        return `${hour}:${minute}:${second} ${translations.hours}`
      } else if (seconds >= 60) {
        return `${minute}:${second} ${translations.minutes}`
      } else {
        return `${seconds} ${translations.seconds}`
      }
    },
    async showDetail (item, config) {
      const setQueryParams = () => {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            slug: item.slug
          }
        })
      }

      const type = item['content_format'].code

      if (['AUDIO', 'VIDEO'].includes(type)) {
        this.$loader.handleShowLoader({
          lang: this.$store.getters.language.lang,
          key: 'NO_MESSAGE'
        })
        const data = await ForYou.entryBySlug(item.slug)
        item.content = data.content
        this.$loader.handleRemoveLoader()
      }

      switch (type) {
        case 'AUDIO':

          this.$globalEvent.$emit('meditation/open', {
            title: item.title,
            fileUrl: item.content
          })

          setQueryParams()
          break

        case 'VIDEO':
          this.$globalEvent.$emit('youtube/open', {
            video: item
          })

          setQueryParams()
          break

        case 'FLOW':
        case 'TEXT':
        case 'READING':
        case 'HTML':
          this.$router.push({
            name: 'ForYouEntryDetail',
            params: {
              slug: item.slug
            },
            query: {
              back: config?.back ? config.back : this.$route.fullPath
            }
          })
          break
      }
    }
  },
  computed: {
    typeIcon () {
      // deprecated
      return (item) => {
        const type = item.content_type.code

        switch (type) {
          case 'VIDEO':
            return 'icon-play-circle-off'

          case 'AUDIO':
            return 'icon-audio'

          case 'READING':
          case 'HTML':
            return 'icon-notes-off'

          case 'INTERACTIVE':
            return 'icon-click-hand-on'
        }
      }
    },
    category () {
      // deprecated
      return (category) => {
        switch (category) {
          case 'MEDITATION':
            return this.$translations.home['content-library'].meditation

          case 'VIDEO_GUIDE':
            return this.$translations.home['content-library']['video-guide']

          case 'BLOG':
            return this.$translations.home['content-library'].blog

          case 'ACTIVITY':
            return this.$translations.home['content-library'].activity
        }
      }
    },
    displayConfig () {
      return (value) => {
        const category = value.content_type.text
        const duration = this.durationFormat(value.duration)
        const tags = value.tags.map(o => o.code).join(', ')

        return {
          ...value,
          description: localStorage.getItem('SELIA_QA_FLAG') === 'true' ?
            `${value.description} Tags: ${tags}` : value.description,
          duration: `${category} • ${duration}`
        }
      }
    },
    otherConfig () {
      return (value) => {
        return {
          ...value,
          thumbnail: value.image
        }
      }
    }
  }
}
